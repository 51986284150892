<template>
  <div id="page">
    <div id="title">
      <p>About</p>
      <div></div>
    </div>

    <div id="about">
      <div id="row">
        <transition name="slide-home-up" appear>
          <div>
            <p id="text">
              Hello, I'm <b>Friday Chukwuamaka Kareen</b>, a mobile developer
              residing in Nigeria. I have a passion for problem-solving, whether
              it involves finding the most elegant code solution or determining
              the perfect chord progression. I thrive on challenges and enjoy
              discovering innovative solutions. My forte lies in translating
              UI/UX designs into visible code, evident in my projects and this
              website. Recently, I have been working with the following:
            </p>
            <p id="text">
              Here are a few technologies I've been working with recently:
            </p>
            <ul class="skills-list">
              <li>Flutter</li>
              <li>Firebase</li>
              <li>Node.js</li>
              <li>Vue</li>
              <li>MongoDB</li>
              <li>Arduino</li>
            </ul>
          </div>
        </transition>
        <transition name="fade3" appear>
          <img
            id="image"
            src="../assets/images/IMG_20200810_170143564~3.jpeg"
            alt="pix"
          />
        </transition>
      </div>
      <!-- <div id="spaced"></div> -->
    </div>
    <!-- <MoreAbout/> -->
  </div>
</template>

<script>
export default {
  name: "AboutPage",
  components: {},
  props: {
    msg: String,
  },
};
</script>

<style scoped>
.slide-home-up-enter {
  opacity: 0;
}
.slide-home-up-enter-active {
  opacity: 0;
  animation: slide-home-up-in 1s ease-out 1s forwards;
  /* transition:  opacity 1s; */
  /* opacity: 0; */
  /* opacity: 1 !important; */
}
.slide-home-up-leave {
  /* opacity: 1 !important; */
}
.slide-home-up-leave-active {
  /* animation: slide1-out 1s ease-out forwards; */
  /* opacity: 1 !important; */
}

ul.skills-list {
  list-style-type: none;
  display: flex;
  gap: 0px 10px;
  font-weight: 600;
  font-size: 0.8em;
  padding-left: 0px;
  margin: 10px 0px 0px;
}

@keyframes slide-home-up-in {
  from {
    transform: translateY(150px);
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

#page {
  min-height: calc(100vh - 144.5px);
  display: flex;
  flex-direction: column;
  margin-top: 69.5px;
  /* margin-bottom: 75px; */
}
#spaced {
  height: 75px;
  /*width: 100%*/
}
#image {
  width: auto;
  height: calc(26vw);
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.27);
  cursor: pointer;
  /* transform: rotateY(180deg); */
}
#image:hover {
  /* animation: flip  ease-in-out 14s forwards; */
  /* transform: rotateY(0deg); */
}
@keyframes flip {
  from {
    transform: rotateY(-180deg);
  }
  to {
    transform: rotateY(180deg);
  }
}
#title p {
  margin-top: 0;
  margin-bottom: 5px;
  font-family: Montserrat-SemiBold;
  font-size: 1.07em;
}
#title {
  display: flex;
  justify-content: center;
  align-items: center;
  /*width: 100%*/
  margin: 0;
  margin-top: 15px;
  margin-bottom: 50px;
  flex-direction: column;

  width: fit-content;
  align-self: center;

  /* display: none; */
}
#title div {
  height: 1.5px;
  width: 60px;
  background-color: black;
}
#logo {
  margin-left: 0;
  display: none;
}
#about {
  display: flex;
  justify-items: center;
  padding: 0 10px 0 35px;
  flex-direction: column;
  margin: 0;
}
#row {
  display: flex;
  align-items: start;
}
@font-face {
  font-family: Montserrat-Medium;
  src: url(../assets/icon/Montserrat/Montserrat-Medium.ttf);
}
#text {
  height: fit-content;
  margin: auto;
  line-height: 35px;
  margin-right: 50px;
  margin-top: 0;
  font-size: 0.85em;
  font-family: Montserrat-Regular;
  text-align: justify;
}

/* 767px and above */
@media only screen and (min-width: 768px) {
  #row {
    margin: 0 5%;
    align-self: center;
  }
}

@media only screen and (max-width: 767px) {
  #page {
    padding: 15px;
    height: unset;
  }
  #spaced {
    height: 0;
  }
  #title div {
    width: 80px;
  }
  #logo {
    margin-left: 0;
    display: none;
  }
  #about {
    padding: 35px 0;
    margin: 0;
  }
  #row {
    flex-direction: column;
    padding: 0;
    align-items: center;
  }
  #text {
    margin: 0;
  }

  .skills-list {
    margin-bottom: 50px !important;
  }

  #image {
    width: 75%;
    height: auto;
  }
  #title {
    display: flex;
    align-items: flex-start;
    margin-bottom: -5px;
    width: unset;
    align-self: unset;
  }
}
</style>
